import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";

import bigLogo from "../assets/bigLogo.png";

function BadUrlScreen() {
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Row>
          <Col xs={0} md={4} />
          <Col xs={12} md={4}>
            <Image src={bigLogo} fluid />
          </Col>
          <Col xs={0} md={4} />
        </Row>
        <div style={{ marginTop: 30, marginBottom: 30, textAlign: "center" }}>
          <h2>Oops</h2>
          <p className="text-secondary">
            Seems like the page you are looking for doesn't exist.
          </p>
        </div>
        <Button className="mt-3" color="#170772" href="https://iumi.cash">
          Go Back
        </Button>
      </div>
    </Container>
  );
}

export default BadUrlScreen;
