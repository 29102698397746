import React from "react";
import { Button, Container, Row, Image, Col } from "react-bootstrap";
import { BrowserView, isIOS, MobileView } from "react-device-detect";
import QRCode from "qrcode.react";

import bigLogo from "../assets/bigLogo.png";
import appStoreBadge from "../assets/appStoreBadge.png";
import googlePlayBadge from "../assets/googlePlayBadge.png";

function OpenAppScreen({ app }) {
  return (
    <Container>
      <BrowserView>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <Row>
            <Col xs={0} md={4} />
            <Col xs={12} md={4}>
              <Image src={bigLogo} fluid />
            </Col>
            <Col xs={0} md={4} />
          </Row>
          <div style={{ marginTop: 30, marginBottom: 30, textAlign: "center" }}>
            <p>
              Open this page on your iOS or Android device or scan the QR code
              below to continue.
            </p>
            <div>
              <QRCode value={window.location.href} />
            </div>
          </div>
          <Button className="mt-3" color="#170772" href="https://iumi.cash">
            More Info
          </Button>
        </div>
      </BrowserView>

      <MobileView>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <Row>
            <Col xs={0} md={4} />
            <Col xs={12} md={4}>
              <Image src={bigLogo} fluid />
            </Col>
            <Col xs={0} md={4} />
          </Row>
          <div style={{ marginTop: 30, marginBottom: 30, textAlign: "center" }}>
            <p>Opening App...</p>
            <p className="text-secondary">
              {`Please ensure you have the iumiCash ${
                app === "merchant" ? "Biz" : ""
              } app installed and then try again.`}
            </p>
            <Button
              href={
                isIOS
                  ? app === "consumer"
                    ? "https://apps.apple.com/us/app/iumicash/id1550254624"
                    : "https://apps.apple.com/us/app/iumicash-biz/id1550255690"
                  : app === "consumer"
                  ? "https://play.google.com/store/apps/details?id=consumer.production.cash.iumi"
                  : "https://play.google.com/store/apps/details?id=merchant.production.cash.iumi"
              }
              variant="link"
              style={{
                marginTop: 30,
              }}
            >
              <Image
                src={isIOS ? appStoreBadge : googlePlayBadge}
                fluid
                style={{ height: isIOS ? undefined : "60px" }}
              />
            </Button>
          </div>
          <Button color="#170772" href="https://iumi.cash">
            More Info
          </Button>
        </div>
      </MobileView>
    </Container>
  );
}

export default OpenAppScreen;
