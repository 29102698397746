import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import BadUrlScreen from "./screens/BadUrlScreen";
import OpenAppScreen from "./screens/OpenAppScreen";
import "./custom.scss";

function App() {
  const [loading, setLoading] = useState(true);
  const [invalidURL, setInvalidURL] = useState(false);
  const [app, setApp] = useState("");

  useEffect(() => {
    console.log("pathname", window.location.pathname);
    console.log("search", window.location.search);

    if (window.location.pathname && window.location.pathname !== "/") {
      const [appType, urlPath] = window.location.pathname.split("--");

      let appSlug = "";
      if (appType === "/staging/") {
        appSlug = "iumicash-staging";
        setApp("consumer");
      } else if (appType === "/production/") {
        appSlug = "iumicash";
        setApp("consumer");
      } else if (appType === "/merchant-staging/") {
        appSlug = "iumi-biz-staging";
        setApp("merchant");
      } else if (appType === "/merchant-production/") {
        appSlug = "iumi-biz";
        setApp("merchant");
      } else {
        setInvalidURL(true);
        setLoading(false);
        return;
      }

      setLoading(false);

      console.log(
        "opening: ",
        `${appSlug}://${urlPath}/${window.location.search}`
      );

      window.location.href = `${appSlug}://${urlPath}/${window.location.search}`;
    }
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            component={() => {
              window.location.href = "https://iumi.cash";
              return null;
            }}
          />
          <Route>
            {loading ? undefined : invalidURL ? (
              <BadUrlScreen />
            ) : (
              <OpenAppScreen app={app} />
            )}
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
